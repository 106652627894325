@import '../../../../../styles/colors';
@import '../../../../../styles/common';

.getting-started {
    h3,
    h4 {
        font-size: 16px;

        text-transform: uppercase;
    }

    ol,
    .sdk ul,
    .row {
        padding-left: 15px;
    }

    ul li,
    ol li {
        margin-bottom: 8px;
    }

    h3 {
        @extend .bold;
        font-size: 18px;

        margin: 0 0 5px;
    }

    p {
        line-height: 21px;

        margin-bottom: 15px;
    }

    ol {
        margin-bottom: 15px;
    }

    i > p.bold,
    .sdk .title {
        @extend .bold;
        margin-bottom: 0;
    }

    .section {
        margin-bottom: 40px;

        .strawberry-text {
            @extend .bold;
            color: $halo-strawberry;
        }
    }

    .image {
        text-align: center;
    }

    .row {
        display: flex;
        flex-direction: row;

        flex-wrap: wrap;
        justify-content: space-between;

        h3 {
            font-size: 18px;
            font-weight: bold;

            margin: 15px 0 20px 0;
        }
    }

    .console .image {
        width: 262px;
        margin: 0 15px 15px 0;
        padding: 20px;

        background-color: $gray4;

        align-items: center;
    }

    .sdk .image {
        width: 25%;
        padding: 0 40px 30px 0;
    }

    .blue-text {
        color: $halo-aura;
        cursor: pointer;

        &:hover {
            color: $halo-aura-hover;
            text-decoration: underline;
        }
    }
}
