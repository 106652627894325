@import '../../../../../styles/colors';

.getting-started {
    h4 {
        font-size: 16px;

        text-transform: capitalize;
    }

    h3 {
        font-size: 18px;

        margin: 0 0 5px;
    }

    p {
        line-height: 21px;

        margin-bottom: 15px;
    }

    ol {
        margin-bottom: 15px;
        padding-left: 15px;

        li {
            margin-bottom: 8px;
        }
    }

    i > p.bold {
        margin-bottom: 0;
    }

    .section {
        margin-bottom: 40px;

        .strawberry-text {
            color: $halo-strawberry;
        }
    }
    
    .blue-text {
        color: $halo-aura;
        cursor: pointer;

        &:hover {
            color: $halo-aura-hover;
            text-decoration: underline;
        }
    }
}
