@import "../../../styles/colors";
@import "../../../styles/common";

#callback-panel,
#companies-accordion,
#elements-accordion,
#pub-panel,
#types-accordion {
  .section-header {
    margin-top: 20px;
  }

  &.accordion {
    .accordion-item {
      border: 1px solid $gray4;
    }

    h2.accordion-header {
      margin: 0;

      button.accordion-button {
        width: 100%;
        font-size: 16px;
        text-align: left;
        background-color: transparent;
        border: none;
        padding: 20px 15px;
        line-height: 21px;
        font-weight: 700;
        color: $gray7;

        .buttonlink {
          position: absolute;
          right: 12%;
          color: #2e5eaf;
          font-size: 14px;
        }
      }
    }

    .accordion-body {
      padding: 20px;
    }
  }
}

#callback-panel {
  .accordion-body {
    padding: 0 !important;
  }
}
