@import '../../../../../styles/colors';

.data-types .data {
    margin-top: 50px;

    h4 {
        font-weight: normal;
    }

    .panel-group {
        margin: 0;

        > div:not(:last-child) .panel.panel-default {
            border-bottom: 0;
        }
    }

    .xml,
    .json {
        margin-top: 40px;
    }

    .grid.table {
        margin-bottom: 0;

        tbody > tr > td {
            &.name,
            &.occurs,
            &.property,
            &.type {
                width: 25%;
            }

            &.description {
                width: 50%;
            }

            &.name,
            &.type {
                a {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}
