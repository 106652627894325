@import '../../../../styles/colors';
@import '../../../../styles/common';

.authentication-component {
    h3 {
        font-size: 18px;

        margin-top: 10px;

        text-transform: uppercase;
        @extend .bold;
    }

    ul {
        width: 100%;
        margin: 15px 0 30px 0;
        padding: 0;

        li {
            margin: 0 0 7px 15px;

            color: $halo-aura
        }
    }

    .tr-override{
        text-align: left;
    }
}
