@import '../../../styles/colors';

.custom-tooltip.tooltip {
    &.tooltip.in {
        opacity: 1;
    }

    &.right {
        margin-left: 5px;

        .tooltip-arrow {
            top: calc(50% - 5px) !important;
            left: -5px;

            border-width: 10px 10px 10px 0;
            border-right-color: $gray3;

            &::after {
                position: absolute;
                top: calc(50% - 10px);
                left: 2px;

                content: '';

                border: 10px solid transparent;
                border-right-color: $white;
                border-left-width: 0;
            }
        }
    }

    .tooltip-inner {
        max-width: 300px;
        padding: 15px;

        text-align: start;

        color: $black;
        border: 1px solid $gray3;
        border-radius: 0;
        background: $white;
    }
}
