@import '../../../../../styles/colors';

.pub-sub {
    .image {
        text-align: center;
    }

    ol {
        margin-bottom: 25px;
        padding: 0;

        li {
            margin: 0 0 8px 15px;
        }
    }

    .color {
        color: $red;
    }

    h3,
    h4 {
        text-transform: uppercase;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        margin-top: 30px;
    }

    p {
        margin: 0 0 25px;
    }

    .panel-body {
        padding: 0 15px 15px;
    }

    .blue-text {
        color: $halo-aura;
        cursor: pointer;

        &:hover {
            color: $halo-aura-hover;
            text-decoration: underline;
        }
    }
}
