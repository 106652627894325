@import '../styles/colors';

.bold {
    font-weight: bold;
}

code {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 13px;

    padding: 0;

    color: $gray8;
    background-color: transparent;
}

h2 {
    font-size: 24px;
    font-weight: bold;

    margin: 0 0 20px;
}
