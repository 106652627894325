@import '../../../styles/colors';

.formTitle {
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: center;
    justify-content: center;

    h3 {
        font-size: 18px;
        font-weight: bold;

        width: 100%;
        margin: 0 0 20px 0;
        padding-bottom: 10px;

        text-align: center;
        letter-spacing: .7px;
        text-transform: uppercase;

        border-bottom: 1px solid $gray6;
    }

    .required-fields {
        font-size: 12px;

        width: 100%;

        text-align: right;

        color: $red;
    }
}
