@import '../../../styles/colors';

.code pre {
    margin-bottom: 15px;
    padding: 20px;

    border-radius: 0;
}

.code pre {
    font-size: 12px;

    color: $white;
    background-color: $gray7;
}

.code.url pre {
    font-size: 14px;

    color: $gray7;
    border: 1px solid $gray4;
    background: none;
}
