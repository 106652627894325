@import "../../../styles/colors";

.licenses-team-container {
  background: $gray1;
  width: 85%;
  padding: 20px;

  .environment-dev.active.btn.btn-primary,
  .environment-prod.active.btn.btn-primary,
  .environment-dev.active.btn.btn-default,
  .environment-prod.active.btn.btn-default {
    width: 25%;
    display: inline-table;
    background-color: $halo-strawberry;
    color: $white;
    box-shadow: none;
  }

  .environment-dev.btn.btn-primary,
  .environment-prod.btn.btn-primary,
  .environment-dev.btn.btn-default,
  .environment-prod.btn.btn-default {
    width: 25%;
    display: inline-table;
    background-color: $white;
    color: $halo-strawberry;
    border: 1px solid $halo-strawberry;
    box-shadow: none;
  }

  .form-group {
    display: inline;

    .company-select {
      width: 50%;
      display: inline-table;
      padding: 1.5em 1.5em 1.5em 0;

      .Select {
        max-height: 40px !important;
      }

      .glyphicon {
        font-size: 16px !important;
        margin-top: 5px;
      }
    }
  }

  .grid.table {
    > tbody {
      td {
        color: #4a4a4a !important;
      }

      .placeholder {
        opacity: 1;
        background-color: transparent;
      }

      .ellipsis {
        .dropdown-menu {
          border-radius: 0;
        }

        .dropdown-toggle::after {
          display: none;
        }
      }
    }
  }
}
