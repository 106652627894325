@import "../../styles/colors";

.guides-component {
  max-width: 100%;
  width: 100% !important;
  background: linear-gradient(173deg, $white 32.4%, $gray1 32.5%, $white 70%);
  background: -webkit-linear-gradient(277deg, $white 32.4%, $gray1 32.5%, $white 70%);

  .sticky-wrapper {
    position: sticky !important;
    position: -webkit-sticky;
    top: 0;
    height: 50%;
    z-index: 10;
    margin-left: 45px;
    margin-top: 5px;
  }

  .Menu-component {
    top: 0;

    margin-right: 50px;

    align-self: flex-start;
  }

  .content {
    display: flex;
    min-height: 625px;

    .content-wrapper {
      margin: 0 10px;
      padding-top: 0px;

      pre {
        background-color: $gray7;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: $white;
        display: block;
        padding: 9.5px;
        font-size: 13px;
        line-height: 1.42857;
        margin: 0 0 10px;
        word-break: break-all;
        word-wrap: break-word;

        code {
          white-space: pre-wrap;
        }
      }
    }
  }
}
