@import "../../../styles/colors";
.Breadcrumbs-component {
  font-size: 13px;

  .breadcrumb {
    padding: 8px 0;
    margin-bottom: 20;

    background-color: transparent;

    > li + li:before {
      padding: 0;

      content: "";
    }

    .active {
      color: $gray5;
      font-family: "Nunito Sans", Arial sans-serif;
    }

    .breadcrumb-title:hover {
      text-decoration: underline;
    }
  }

  .glyphicon-chevron-right {
    font-size: 8px;

    position: initial;
    top: 0;

    padding: 0 6px;
  }
}
