@import '../../../styles/colors';

.truncate-wrapper {
    word-break: break-word;

    width: 100%;
}

.license-truncated-tooltip {
    margin-left: 20px;

    .tooltip-inner {
        background-color: $white;
        border: solid $gray4 1px;
        border-radius: 0px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.23);
        color: $gray7;
    }

    .tooltip-arrow {
        display: none;
    }
}

.license-truncated-tooltip > .tooltip-inner:after, .license-truncated-tooltip > .tooltip-inner:before {
    position: absolute;
    display: block;
    content: '';
    bottom: 88%;
    width: 0px;
    height: 0px;
    border-style: solid;
    pointer-events: none;
}

.license-truncated-tooltip > .tooltip-inner:after {
    left: 10px;
    border-color: transparent;
    border-bottom-color: white;
    border-width: 6px;
}

.license-truncated-tooltip > .tooltip-inner:before {
    left: 8px;
    border-color: transparent;
    border-bottom-color: $gray4;
    border-width: 8px;
}