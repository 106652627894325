@import "../../styles/colors";

.Layout-component {
  z-index: 10;

  background-color: white;
  position: relative;
  min-height: 100vh;
  height: 100%;

  .content-wrap,
  .login.container,
  .loginsupport.container,
  .reset-password.container,
  .change-password.container {
    padding-bottom: 18rem;
  }

  // .Landing-component {
  //   padding-bottom: 16rem;
  // }

  .img-box {
    position: relative;

    span {
      position: absolute;
      top: 0;

      display: flex;

      width: 100%;
      height: 100%;

      justify-content: center;
      align-items: center;

      h1 {
        color: $white;

        font-family: "Nunito Sans Light", Arial sans-serif;
      }
    }

    .img-responsive {
      width: 100%;
    }
  }

  .haloLogoCursor.container {
    width: auto;
    margin: 0;
    padding: 0;

    .navbar-brand {
      padding: 0;
      img.navbar-brand {
        float: left;
        padding: 0;
        height: 30px;
        margin-right: 50px;
      }
    }
  }
}
