.profile-component {
  &.container {
    width: 1170px;
  }

  margin: 50px auto;

  .sticky-wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    height: 50%;
    margin-top: -45px;
  }

  .content {
    display: flex;
    min-height: 600px;

    .Menu-component {
      margin-right: 50px;

      flex: none;
      align-self: flex-start;
    }

    .content-wrapper {
      width: 100%;
      margin: 0 10px;
    }
  }
}
