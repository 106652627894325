@import '../../../styles/colors';

.spinner-wrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: $black-with-alpha;

    &.full-screen {
        position: fixed;
    }

    .spinner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 4.4rem;
        height: 4.4rem;
        margin: auto;

        animation: spinner infinite 1.5s linear;

        border: .3rem solid;
        border-color: $gray7 $white;
        border-radius: 2.5rem;
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
}
