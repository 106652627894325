@import "../../../styles/colors";

.custom-checkbox {
  position: relative;

  margin-bottom: 0;

  pointer-events: none;

  div {
    display: inline-block;
  }

  &.disabled {
    opacity: 0.4;

    .checkbox-placeholder,
    .checkbox-icon {
      pointer-events: none;
    }

    .checkbox-icon {
      border: 1px solid $gray3;
      background: $gray1;

      &::before {
        background: repeating-linear-gradient(90deg, $gray1, $gray1 3px, transparent 3px, transparent 12px);
      }
    }
  }

  .checkbox-input {
    position: absolute;

    opacity: 0;

    &:checked + .checkbox-icon {
      border-color: $halo-aura;

      &::after {
        opacity: 1;
      }
    }

    &.disabled + .checkbox-icon {
      border: 1px solid $gray3;
      background: $gray1;
    }

    &.ng-not-empty + .checkbox-icon {
      border-color: $halo-aura;
    }
  }

  .checkbox-icon {
    position: absolute;
    top: 1px;
    left: 0;

    width: 17px;
    height: 17px;

    cursor: pointer;
    pointer-events: auto;

    border: 1px solid $gray4;
    border-radius: 0;
    background: $white;
  }

  .checkbox-icon::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 15px;
    height: 15px;

    content: "";

    opacity: 1;
    background: repeating-linear-gradient(90deg, $white 3px, $white 3px, transparent 3px, transparent 12px);
  }

  .checkbox-icon::after {
    position: absolute;
    top: 3px;
    left: 2px;

    width: 11px;
    height: 7px;

    content: "";
    transform: rotate(-45deg);

    opacity: 0;
    border: 3px solid $halo-aura;
    border-top: 0;
    border-right: 0;
  }

  .checkbox-placeholder {
    font-weight: normal;

    position: relative;

    padding-left: 30px;

    cursor: pointer;
    pointer-events: auto;
    font-weight: 700;
  }
}
