@import '../../../styles/colors';
@import '../../../styles/common';

.panel-group .panel.panel-default .panel-heading .panel-title {
    color: $gray7 !important;
}

.panel-group .panel.panel-default .panel-heading .panel-title:not(.glyph) {
    width: 70%;
}

.panel-group {
    margin: 20px 0;

    .panel {
        + .panel {
            margin-top: 0;

            border-top: 0;
        }

        .panel-title + .glyph {
            margin-left: auto;
        }

        .panel-default .panel-heading .panel-title .panel-right-end.glyph {
            color: $halo-aura;
        }

        .panel-body {
            padding: 0;
            margin-left: 20px;
        }

        .license-redirect {
            cursor: pointer;
            color: $halo-aura;

            &:hover {
                color: $halo-aura-hover;
            }
        }

        &.panel-default {
            border-radius: 0;

            .panel-heading {
                display: flex;
                align-items: center;

                height: 60px;
                padding: 24px 15px;

                background-color: transparent;

                > div {
                    display: inline-block;

                    a {
                        &:hover,
                        &:active,
                        &:focus {
                            color: inherit;
                        }
                    }
                }

                .panel-title:not(.glyph) {
                    margin-right: 0;

                    font-size: 16px;

                    color: $halo-aura;

                    font-weight: bold;
                }

                .counter {
                    margin: 0 auto 0 20px;
                    padding: 3px 16px;

                    background-color: $gray5;
                }

                .glyphicon {
                    color: $halo-aura;
                }
            }

            &.disabled {
                pointer-events: none;

                a,
                span::before {
                    color: $gray5;
                }

                span::before {
                    opacity: .6;
                }
            }
        }

        .panel-heading + .panel-collapse > .panel-body,
        .panel-heading + .panel-collapse > .list-group {
            border-top: 0;
        }

        .grid.table > thead > tr > th {
            text-align: left;
        }

        .move-to-production-button {
            height: 32px;
            margin-bottom: 13px;
            padding: 5px 15px;
        }
    }
}
