@import "../../../../styles/colors";

.compatibility-component {
  .panel-group,
  .accordion {
    width: 600px;
    margin: 0px;
    padding-bottom: 15px;
  }

  h3 {
    margin: 10px 0 15px;
    text-transform: capitalize;
    font-size: 24px;
  }

  h4 {
    font-weight: bold;
    text-transform: capitalize;
    padding-left: 10px;
  }

  a {
    color: $gray7;
  }

  a:hover {
    color: $gray7;
  }

  p {
    margin-bottom: 35px;
  }

  .accordion {
    .accordion-item {
      clear: both;
      border: 1px solid #ddd;

      h2.accordion-header {
        margin-bottom: 0;
        background-color: $gray2;
        border-bottom: 1px solid #ddd;

        button {
          background-color: $gray2;
          padding: 10px 15px 10px 15px;
          width: 100%;
          text-align: left;
          font-size: 16px;
          border: none;
          line-height: 1em;
        }
      }

      .accordion-body {
        .endpoint-container {
          margin-left: 20px;
          clear: both;

          &:hover {
            background-color: #eef9fe;
          }
        }

        .endpoint-method {
          display: inline-block;
          border-bottom: 1px solid #ddd;
          width: 100%;
          padding: 5px 8px 5px 0;

          button {
            width: 70px !important;
            height: 30px;
            cursor: default;
            text-align: center;
            float: left;
            line-height: 1em;
            padding: 8px;
            margin: 5px 8px 0;

            &:active {
              border: none;
            }

            &.GET-button {
              background-color: $get-method-blue;
            }

            &.POST-button {
              background-color: $post-method-green;
            }

            &.PUT-button {
              background-color: $put-method-orange;
            }

            &.DELETE-button {
              background-color: $delete-method-red;
            }
          }
        }

        .endpoint-description {
          display: inline-block;
          padding: 8px;
          line-height: 1.5;
          width: 83%;
        }
      }
    }
  }
  .panel-group .panel {
    .panel-heading {
      background-color: $gray2;
      height: auto;
      padding: 10px 15px 10px 15px;
    }

    .panel-body {
      padding: 0;
    }
  }

  img {
    width: 50px;
    padding-right: 10px;
  }
}
