@import "../../../styles/colors";

.grid-wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid $gray3;
}

.grid.table {
  margin-bottom: 0;

  > thead > tr {
    height: 50px;

    background-color: $gray3;

    > th {
      text-align: center;
      vertical-align: middle;
      background-color: $gray3;
      border-bottom: 0;
    }
  }

  > tbody > tr {
    height: 50px;
    text-align: center;

    border-top: 2px solid $gray3;

    > td {
      vertical-align: middle;
      background-color: transparent;
      border-top: 0;
    }
  }

  > tbody > tr:hover {
    background-color: $table-row-hover;
  }

  .img-responsive {
    height: 60px;
  }

  .custom-checkbox .checkbox-icon {
    position: relative;
  }
}

.pagination-wrapper {
  text-align: center;

  border-top: 2px solid $gray3;
}
