@import '../../../../styles/common';

.documentation {
    h4 {
        text-transform: uppercase;
    }

    h3 {
        margin: 0 0 8px;
    }

    p {
        line-height: 21px;

        margin-bottom: 15px;
    }

    code {
        @extend code;
    }

    ol {
        margin-bottom: 15px;
        padding-left: 15px;

        li {
            margin-bottom: 8px;
        }
    }

    .grid.table {
        th,
        td {
            padding: 12px 0 12px 20px;

            text-align: left;
        }

        td {
            padding: 20px 0 20px 20px;
        }
    }

    .section {
        margin-bottom: 40px;
    }

    .setup {
        .project-setup-title {
            font-weight: 700;
        }
    }

    .equipment-observer .image {
        text-align: center;
    }

    .life-cycle {
        h3 {
            text-transform: uppercase;
        }

        h4 {
            margin: 10px 0;
        }

        .callback {
            margin-top: 25px;
        }
    }

    .delegates .observer-method {
        width: 30%;

        word-wrap: break-word;
    }
}
