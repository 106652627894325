form {
    width: 100%;

    .buttons {
        display: flex;

        margin-top: 10px;

        .btn.btn-default.dark {
            margin-left: 25px;
        }
    }
}

.enrollment-type {
    margin-top: 20px;
}
