@import "../../../styles/colors";

.introduction-component {
  width: 750px !important;
  font-size: 14px;
  background-color: $white;
  padding: 30px 20px;
  height: 625px;
  box-shadow: 0px 3px 10px #00000029;
  margin-bottom: -50px;

  h2 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  .outer-container {
    display: flex;

    .box {
      width: 330px;
      height: 390px;
      margin: 20px;
      background-color: $white;
      box-shadow: 0px 3px 6px #00000029;
      position: relative;

      h4,
      p,
      a {
        padding: 10px;
      }

      h4 {
        color: $gray7;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px;
      }

      a {
        position: absolute;
        bottom: 0px;
      }

      img {
        width: 100%;
        min-height: 226px;
        max-height: 226px;
      }
    }
  }
}
