$red-hover:         #850d00;
$red:               #ab1000;
$halo-strawberry:   #eb5f60;
$halo-strawberry-hover:   #c44f4f;
$halo-punch-red:    #e81f41;
$halo-sunset:       #f2732c;
$halo-solar:        #ffc857;
$green:             #739849;
$halo-aura:         #2E5EAF;
$halo-aura-hover:   #20427D;
$table-row-hover:   #EEF9FE;
$halo-coral:        #f4846f;
$post-method-green: #63CC90;
$get-method-blue:   #61AFFE;
$put-method-orange: #EEA02D;
$delete-method-red: #E73A3A;

$white: #fff;
$gray1: #f5f5f5;
$gray2: #f2f2f2;
$gray3: #e9e9e9;
$gray4: #dbdbdb;
$gray5: #a0a0a0;
$gray6: #7a7a7a;
$gray7: #4a4a4a;
$gray8: #3a3a3a;
$black: #000;

$black-with-alpha: rgba(0, 0, 0, .25);

$icon-font-path: "bootstrap-sass/assets/fonts/bootstrap"