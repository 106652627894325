@import '../../../styles/colors';

.Menu-component {
    min-width: 250px;

    .title {
        margin-top: 0;
        margin-right: 20px;
        padding: 2px 0 10px;

        text-transform: uppercase;

        color: $halo-strawberry;
        border-bottom: 4px solid $gray4;

        font-family: "Nunito Sans Bold", Arial sans-serif;
        line-height: 32px;
    }

    .sub-menu {
        color: $halo-aura;
    }

    a,
    .sub-menu {
        display: block;

        margin: 10px 0;

        font-size: 14px;
        line-height: 21px;
        color: $gray7;

        &:hover {
            color: $gray8;
        }
    }

    .sub-menu .sub-menu-component {
        margin-left: 15px;
        padding-left: 10px;
    }

    a.active {
        font-family: "Nunito Sans Bold", Arial sans-serif;
        color: $halo-strawberry;
        padding-left: 10px;
        border-left: 2px solid $halo-strawberry;
        cursor: default;
    }

    a.active:hover {
        color: $halo-punch-red;
    }
}
