@import '../../../../../styles/common';

.workout-preset {
    h3 {
        font-size: 18px;

        text-transform: uppercase;
    }

    code {
        @extend code
    }

    .indent {
        padding-left: 15px;
    }
}
