@import '../../../../styles/colors';
@import '../../../../styles/common';

.overview-component {
    h3 {
        font-size: 18px;

        margin-top: 10px;

        text-transform: uppercase;
        @extend .bold;
    }

    ul {
        width: 100%;
        margin: 15px 0 30px 0;
        padding: 0;

        li {
            margin: 0 0 7px 15px;

            color: $halo-aura
        }
    }

    ol {
        margin-bottom: 8px;
        padding-left: 15px;
        font-weight: bold;
    }

    .strawberry-text {
        color: $halo-strawberry;
    }

    .blue-text {
        color: $halo-aura;

        &:hover {
            cursor: pointer;
            color: $halo-aura-hover;
            text-decoration: underline;
        }
    }

    .image {
        text-align: center;
    }

    .row {
        padding-left: 15px;

        display: flex;
        flex-direction: row;

        flex-wrap: wrap;
        justify-content: space-between;

        h3 {
            font-size: 18px;
            font-weight: bold;

            margin: 15px 0 20px 0;
        }
    }

    .sdk .image {
        width: 25%;
        padding: 0 40px 30px 0;
    }
    
    p {
        line-height: 21px;
    }
}
