@import '../../../../styles/common';

.Archives-component {
    h2 {
        font-size: 24px;

        margin: 0 0 20px;
        @extend .bold;
    }

    .content {
        margin: 0;
    }

    .blue-text {
        color: $halo-aura;
        cursor: pointer;

        &:hover {
            color: $halo-aura-hover;
            text-decoration: underline;
        }
    }

    .redirectToSignIn {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    .redirectToSignInButton {
        margin-top: 20px;
    }
}
