@import "../../../styles/colors";

.real-time-component {
  width: 750px !important;
  font-size: 14px;
  background-color: $white;
  padding: 30px 20px;
  box-shadow: 0px 3px 10px #00000029;

  h2 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  .key-benefits {
    display: flex;

    .box {
      font-size: 13px;
      width: 195px;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
      font-style: italic;
      color: $gray7;

      .alt {
        color: $halo-strawberry;
      }

      img {
        margin: auto;
      }
    }
  }

  .prereq > img {
    height: 300px;
    margin-left: 50px;
    margin-bottom: 15px;
  }

  thead {
    background-color: #dddddd;
    border: solid #dddddd;

    th {
      font-weight: normal;
      padding: 10px;
      width: 20%;
    }
  }

  tbody {
    border: solid #dddddd;

    td {
      border: solid #dddddd;
      padding: 10px;

      li {
        margin-left: -25px;
      }
    }
  }
}
