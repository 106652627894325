@import "../../styles/colors";

.login.container {
  width: 500px;
  padding: 50px 15px 3rem !important;

  h2 {
    font-weight: bold;

    text-align: center;
  }

  a {
    font-size: 12px;

    text-decoration: underline;
  }

  form {
    width: 100%;
    padding-top: 20px;

    .required {
      display: none;
    }

    .help-block {
      font-size: 12px;
    }

    .custom-checkbox {
      display: block;

      margin: 5px 0 15px;

      .checkbox-placeholder {
        font-weight: normal;
      }
    }

    .password-field {
      padding-bottom: 0px;
    }

    .btn.btn-default.next,
    .btn.btn-primary.next {
      margin-left: auto;
      margin-right: auto;

      display: flex;
    }

    .forgot-pass-text {
      color: $halo-aura;
    }

    .divider {
      width: 100%;

      text-align: center;

      padding: 25px 0px 25px 0px;
    }

    .divider span {
      color: $halo-aura-hover;
    }

    .divider hr {
      margin-left: auto;

      margin-right: auto;

      width: 40%;
    }

    .left {
      float: left;
    }

    .right {
      float: right;
    }

    hr {
      border-color: $gray4;

      margin-top: 10px;
    }

    .btn.btn-primary.tertiary {
      margin-left: auto;
      margin-right: auto;
      box-shadow: none;

      display: flex;

      background-color: $white;
      color: $halo-aura-hover;
      border: 1px solid $halo-aura-hover;
    }

    // .buttons {
    //     padding-top: 20px;
    // }
  }
}
