@import '../../../../styles/common';

.WebArchives-component {
    h2 {
        font-size: 24px;

        margin: 0px;
        @extend .bold;
    }

    .content {
        margin: 0 0 30px 0;
    }

    .redirectToSignIn {
        color: $white;

        &:hover {
            color: $white;
        }
    }
}
