@import '../../../../../styles/common';

.workout-preset {
    h3 {
        font-size: 18px;

        text-transform: uppercase;
    }

    ul {
        margin: 0;
        padding: 0;

        list-style: none;

        li {
            margin: 0 0 8px 15px;
        }
    }

    code {
      @extend code;
    }

    .without-indent {
        margin-bottom: 0;
    }
}
