@import '../../../styles/colors';

.team-member {
    width: 100%;

    .role {
        display: flex;
        flex-direction: row;

        .form-group {
            flex: auto;

            margin-right: 30px;
        }

        img {
            position: relative;
            top: 48px;

            width: 16px;
            height: 16px;

            cursor: pointer;
        }
    }

    #rolePermissionsTooltip {
        //Fix tooltip position on modal window
        top: 247px !important;
        left: 578px !important;

        .tooltip-inner {
            min-width: 203.5px;
        }
    }
}
