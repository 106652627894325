@import "../../styles/colors";

.Developer-api-component {
  margin-bottom: 50px;

  .docsHeader {
    font-size: 21px;
    color: $white;
    font-weight: 400;
  }

  .nav-tabs {
    position: sticky;
    z-index: 1;
    top: 70px;
    min-height: 48px;
    display: flex;

    border-bottom: 0;
    background-color: $gray7;
    justify-content: left;

    flex-direction: row;

    li:first-child {
      flex: 0 0 30%;
      margin-left: 3.25%;

      button {
        font-size: 21px;
        padding-top: 11px;
        padding-bottom: 0;
      }
    }

    @media screen and (min-width: 1920px) {
      li:first-child {
        margin-left: 17%;
        flex: 0 0 20%;
      }
    }

    @media screen and (min-width: 1680px) and (max-width: 1919px) {
      li:first-child {
        margin-left: 12.5%;
        flex: 0 0 20%;
      }
    }

    > li > button {
      &,
      &:hover,
      &:focus {
        margin-right: 0;
        border: 0;
        border-radius: 0;
        outline: none;
        color: $white !important;
        background-color: $gray7;
      }
    }

    > li.active > button,
    > li > button.active {
      background-color: $gray6;
    }

    > li {
      margin-bottom: 0;
      padding: 0;

      > a,
      > button {
        font-size: 14px;

        padding: 15px 20px 12px;
      }
    }

    // > li.active > button,
    // > li > button.active {
    //   &::after {
    //     width: 0px;
    //     height: 0px;
    //     border-left: 10px solid transparent;
    //     border-right: 10px solid transparent;
    //     border-top: 10px solid $gray6;
    //     content: " ";
    //     position: relative;
    //     bottom: -10px;
    //     left: 40%;
    //     white-space: pre;
    //     word-wrap: break-word;
    //   }
    // }
  }

  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
}
