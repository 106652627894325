.Developer-sdk-component {

    .sticky-wrapper {
        position: sticky;
        position: -webkit-sticky;
        top: 125px;
        height: 50%;
    }

    .Menu-component {
        top: 180px;

        margin-right: 50px;

        align-self: flex-start;
    }

    .content {
        display: flex;

        .content-wrapper {
            margin: 0 10px;
            padding-top: 55px;
        }
    }
}
