@import "../../styles/colors";

.Footer-component {
  width: 100%;
  padding: 20px 0 30px;
  margin-top: 0;
  bottom: 0;
  background-color: $gray7;
  float: left;

  &.landing {
    padding: 42px 132px 97px;

    color: $gray1;

    font-size: 12px;

    .container:not(.bottom) {
      display: none;
    }

    .container.bottom {
      display: block;

      margin: 0;
      padding: 0;

      p {
        margin-bottom: 5px;

        color: $gray1;
      }

      a {
        cursor: pointer;

        color: $gray1;

        font-size: 12px;

        &:visited {
          color: $gray1;
        }
      }

      a:not(.about) {
        text-decoration: underline;
      }

      .about {
        display: block;

        margin-top: 25px;

        font-weight: 700;
      }
    }

    hr {
      display: none;
    }
  }

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 400px;

    .form-group {
      width: 100%;
      margin-right: 5px;
      margin-bottom: 0;

      .form-control {
        color: $gray4;
        border-color: $gray8;
        background-color: $gray8;

        &:focus,
        &:active {
          border-color: $gray8;
        }
      }
    }

    .btn.btn-default {
      height: 40px;
      padding: 10px 15px;

      color: $white;
      background-color: $gray5;
    }
  }

  .container {
    display: flex;

    h4 {
      color: $gray1;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 15px;
    }

    &:after,
    &:before {
      content: none;
    }
  }

  hr {
    max-width: 1138px;
    margin: 20px auto;
    border: 0.5px solid $gray6;
  }

  .bottom {
    justify-content: space-between;
    padding-top: 2px;

    p {
      color: $white;

      font-size: 12px;

      a {
        text-decoration: underline;

        color: $white;
      }
    }

    .about {
      color: $gray1;

      font-family: "Nunito Sans Bold", Arial, sans-serif;
      font-size: 14px;
    }
  }

  .info-wrapper {
    display: flex;

    .resources,
    .brands {
      h4 {
        font-family: "Nunito Sans Bold", Arial, sans-serif;
      }

      a {
        display: block;

        color: $white;

        font-family: "Nunito Sans", Arial, sans-serif;
        font-size: 14px;
      }
    }

    .resources {
      margin-right: 45px;
    }
  }

  .image-box {
    display: flex;
    align-items: flex-end;

    img {
      height: fit-content;
    }
  }

  @media (min-width: 992px) {
    .container {
      width: 95%;
    }
  }

  @media (max-width: 1070px) {
    .container {
      align-items: center;
      flex-direction: column;

      > div {
        margin: 10px;
      }
    }
  }

  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }

  .signupFooter {
    display: none;
  }
}
