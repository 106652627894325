@import '../../../../styles/colors';

.compatibility-component {
    .grid-wrapper {
        border: none;

        .grid.table > tbody {
            tr {
                text-align: left;
            }
            
            td {

                button {
                    width: 70px;
                    height: 30px;
                    text-align: center;
                    cursor: default;
                    padding: 0;
                }

                .GET-button {
                    background-color: $get-method-blue;
                }

                .POST-button {
                    background-color: $post-method-green;
                }
                
                .PUT-button {
                    background-color: $put-method-orange;
                }
                
                .DELETE-button {
                    background-color: $delete-method-red;
                }
            }
        }
    }

    .grid.table > tbody {
        tr {
            :first-child {
                width: 65px;
            }
        }
    }
}