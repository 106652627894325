.scroll-button {
  &.btn.btn-default,
  &.btn.btn-primary {
    position: fixed;
    right: 50px;
    bottom: 50px;

    display: none;

    padding: 12px 15px;
  }

  &.show {
    display: block;
  }
}
